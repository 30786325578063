import { extend, localize } from "vee-validate";
import { required, max, min } from "vee-validate/dist/rules.umd.js";
import ja from "vee-validate/dist/locale/ja.json";
import { format } from "date-fns";
import { compareStartEndDate } from "@/utils/timeUtil.js";

// バリデーションルール
// https://vee-validate.logaretm.com/v3/guide/rules.html

localize("ja", ja);

//必須
extend("required", required);

//パスワード必須
extend("password-required", {
  ...required,
  message: "パスワードは4文字以上の半角英数字です"
});

const ERR_0006 = '{%1}は{%2}以降の日付を指定してください';
const ERR_DATE_GREATER_THAN = '{%1}は{%2}より前の日付を指定してください';

//メール形式
const email = {
  validate(value) {
    return value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/);
  },
  message: "正しいメールアドレスの形式で入力してください。",
};

//ログイン メールと電話番号
const emailphone = {
  validate(value) {
    const phoneRegex = /^0\d{9,10}$/;
    const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/;

    if (phoneRegex.test(value)) {
      return true;
    } else if (emailRegex.test(value)) {
      return true;
    } else if (/[a-z@.\-_]/i.test(value)) {
      return emailphone.messages.email;
    } else {
      return emailphone.messages.phone;
    }
  },
  messages: {
    email: "正しいメールアドレスの形式で入力してください。",
    phone: "半角数字で10桁または11桁の番号を入力してください。"
  }
};

//パスワード形式
const password = {
  validate(value) {
    return typeof value === "string" && value.length >= 4;
  },
  message: "パスワードは4文字以上の半角英数字です",
};
//パスワード形式
const repassword = {

  params: ["target"],
  validate(value) {
    return typeof value === "string" && value.length >= 4;
  },
  message: "半角英数字4文字以上で入力してください",
};
const passwordRetype = {
  validate(value) {
    return typeof value === "string" && value.length >= 4;
  },
  message: "パスワード再入力を入力してください。",
};

//最大文字数
extend("max", max);

//最小文字数
extend("min", min);

//最小&最大文字数
const minmax = {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ["min", "max"],
  message: " {min}文字以上{max}文字以内で入力してください。",
};

//郵便番号
const postcode = {
  validate(value) {
    return value.match(/^\d{3}-?\d{4}$/g);
  },
  message: "郵便番号を半角7桁で入力してください。",
};

//CCUSID
const ccusid = {
  params: ["target"],
  validate(value) {
    return (value + "").match(/^[0-9]{14}$/);
  },
  message: "{target}を半角数字14桁で入力してください。",
};

const halfSize = {
  validate(value) {
    return value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
  },
  message: "半角英数記号で入力してください。",
};

const halfSize_device = {
  validate(value) {
    return value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) || value.match(/^●{10}/);
  },
  message: "半角英数記号で入力してください。",
};

const fullsize = {
  validate(value) {
    // eslint-disable-next-line no-irregular-whitespace
    return value.match(/^[ぁ-んァ-ン一-龥０-９　ー－・（）「」Ａ-Ｚａ-ｚ]*$/);
  },
  message: "全角英数記号で入力してください。",
};

//カタカナ
const kana = {
  validate(value) {
    return value.match(/^[ァ-ヶー]*$/);
  },
  message: "カタカナで入力してください。",
};

//電話番号
const phone = {
  validate(value) {
    return value.match(/^0\d{9,10}$/);
  },
  message: "半角数字で10桁または11桁の番号を入力してください。",
};

//パスワード比較
const passwordDiff = {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "入力したパスワードが一致しません",
};

//半角数字
const digit = {
  validate(value) {
    return (value + "").match(/^[0-9]*$/);
  },
  message: "半角数字で入力してください。",
};

// 半角英数記号
const numberCharSymbol = {
  validate(value) {
    return value.match(/^[A-Z,a-z,0-9,!"#$%&'()-^@\\[;:\]./=~|`{+*}<>?_]*$/);
  },
  message: "半角英数記号で入力してください。",
};

// file size
const maxsize = {
  validate(value, { size }) {
    let ret = true;
    if (typeof value === "object" && size.match(/^[0-9]*$/)) {
      ret = value.size <= Number(size) * 1024 * 1024;
    }
    return ret;
  },
  params: ["size"],
  message: "アップロードのファイルは、最大{size}MBまでです。",
};

// file type. ex: jpg, png, jpeg
const fileType = {
  validate(value, { types }) {
    if (typeof value !== "object") {
      return true;
    }
    let ext = "";
    let fileName = "";
    if (Array.isArray(types)) {
      let ret = false;
      for (let type of types) {
        ext = `.${type.trim()}`.toLowerCase();
        fileName = value.name.toLowerCase();
        if (fileName.indexOf(ext, fileName.length - ext.length) !== -1) {
          ret = true;
        }
      }
      return ret;
    } else {
      ext = `.${types.trim()}`.toLowerCase();
      fileName = value.name.toLowerCase();
      return fileName.indexOf(ext, fileName.length - ext.length) !== -1;
    }
  },
  params: ["types"],
  message: "{types}ファイルのみアップロード可能です。",
};

// not accept file dynamic ex: video, gif
const notFleDynamic = {
  validate(value) {
    if (typeof value !== "object") {
      return true;
    }
    let formatDynamic = "avi,mp4,gif,mp4,wmv,mkv,vob,flv,mpg,vob,flv,divx";
    let fileName = "";
    let types = formatDynamic.split(',');
    for (let type of types) {
      fileName = value.name.toLowerCase();
      if (fileName.indexOf(type, fileName.length - type.length) !== -1) {
        return false;
      }
    }
    return true;
  },
  message: "動画やgifファイルなど動的なファイルがアップロードできません。",
};

// mix-max pull down
const minmaxPulldown = {
  validate(value, { min, max }) {
    return parseInt(value) >= min && parseInt(value) <= max;
  },
  params: ["min", "max"],
  message: "{min}以上{max}以内の半角数字で入力してください",
};

const decimal = {
  validate(value) {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    return {
      valid: String(value).match(/^\d+(\.\d{1,2})?$/),
    };
  },
  message: "半角数記号(小数点２桁まで)を入力してください。",
};

const compareToEndDate = {
  validate(value, { endDate }) {
    return value <= endDate;
  },
  params: ["endDate"],
  message: "期間（終了）より前の日付を入力してください。",
};

const duplicateConstructionName = {
  validate(value, { isDuplicate }) {
    return isDuplicate === "true";
  },
  params: ["isDuplicate"],
  message: "工事名称が重複しています。",
};

const duplicateTimeZoneName = {
  validate(value, { isDuplicate }) {
    return isDuplicate === "true";
  },
  params: ["isDuplicate"],
  message: "作業時間帯名称はすでに存在します。別の名称を入力してください。",
};

const duplicateDeviceId = {
  validate(value, { isDuplicate }) {
    return isDuplicate === "true";
  },
  params: ["isDuplicate"],
  message: "入力いただいたログインIDは既に使用されております。",
};

const compareToStartDate = {
  validate(value, { startDate }) {
    return value >= startDate;
  },
  params: ["startDate"],
  message: "期間（開始）以降の日付を入力してください。",
};

const compareToEndTime = {
  validate(value, { endTime }) {
    return value < endTime;
  },
  params: ["endTime"],
  message: "開始時間は終了時間より前の時間を入力してください。",
};

const compareToStartTime = {
  validate(value, { startTime }) {
    return value > startTime;
  },
  params: ["startTime"],
  message: "終了時間は開始時間以降を入力してください。",
};

const duplicateTime = {
  validate(value, { check }) {
    return check == 1;
  },
  params: ["check"],
  message: "既存の開始時間～終了時間と重複しています。",
};

const beforeDateNow = {
  validate(value) {
    return format(new Date(value), "yyyy/MM/dd") <= format(new Date(), "yyyy/MM/dd");
  },
  message: "現在日以前の日付を選択してください",
};

const isPastDate = {
  validate(value) {
    return format(new Date(value), "yyyy/MM/dd") < format(new Date(), "yyyy/MM/dd");
  },
  message: "現在日以前の日付を選択してください",
};

const choosePassDate = {
  validate(value) {
    return format(new Date(value), "yyyy/MM/dd") < format(new Date(), "yyyy/MM/dd");
  },
  message: "過去の日付を設定してください。",
};

const beforeRecoveryDate = {
  validate(value) {
    return format(new Date(value), "yyyy/MM/dd") <= format(new Date(), "yyyy/MM/dd");
  },
  message: "本日以前のみを選択してください",
}

const compareDataSelectbox = {
  validate(value, { dataSelectbox }) {
    return value <= dataSelectbox;
  },
  params: ["dataSelectbox"],
  message:"人数項目より小さい値を入力してください。",
};

const compareTotalDataSelectbox = {
  validate(value, { dataSelectbox, total }) {
    return parseInt(dataSelectbox) <= parseInt(total);
  },
  params: ["dataSelectbox", "total"],
  message:"女性と外国人との合計を、人数項目を超えないように入力してください。",
};

const comparePerson = {
  validate(value, { data, totalPerson }) {
    return parseInt(data) <= parseInt(totalPerson);
  },
  params: ["data", "totalPerson"],
  message:"人数項目より小さい値を入力してください。",
};

const compareBloodPressureMax = {
  validate(value, data) {
    return parseInt(data[0]) < parseInt(value);
  },
  message:"最高血圧は最低血圧より高い値を入力してください。",
};
const compareBloodPressureMin = {
  validate(value, data) {
    return parseInt(data[0]) > parseInt(value);
  },
  message:"最低血圧は最高血圧より低い価を入力してください。",
};

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const compareMax = {
  validate(value, data) {
    if (
      !isNumeric(data['data'])
      || !isNumeric(value)
    ) {
      return true;
    }
    return parseInt(data['data']) <= parseInt(value);
  },
  params: ["data", "%1", "%2"],
  message:"{%1}は{%2}以上の値を指定してください",
};
const compareMin = {
  validate(value, data) {
    if (
      !isNumeric(data['data'])
      || !isNumeric(value)
    ) {
      return true;
    }
    return parseInt(data['data']) >= parseInt(value);
  },
  params: ["data", "%1","%2"],
  message:"{%1}は{%2}以下の値を指定してください",
};


const compareFloatMax = {
  validate(value, data) {
    return parseFloat(data['data']) <= parseFloat(value);
  },
  params: ["data", "%1", "%2"],
  message:"{%1}は{%2}以上の値を指定してください",
};

const compareFloatMin = {
  validate(value, data) {
    return parseFloat(data['data']) >= parseFloat(value);
  },
  params: ["data", "%1","%2"],
  message:"{%1}は{%2}以下の値を指定してください",
};

const compareEndDate = {
  validate(value, { endDate }) {
    return value <= endDate;
  },
  params: ["endDate"],
  message: "日付（終了）より前の日付を入力してください",
};
const compareMachineEndDate = {
  validate(value, { endDate }) {
    return !endDate || (value.replaceAll('/','-')) >= (endDate.replaceAll('/','-'));
  },
  params: ["endDate"],
  message: "使用開始日は使用終了日以降の日付を指定してください",
};
const compareStartScheduledDeliveryDate = {
  validate(value, { startDate }) {
    return !startDate || (value.replaceAll('/','-')) <= (startDate.replaceAll('/','-'));
  },
  params: ["startDate"],
  message: ERR_DATE_GREATER_THAN?.replace('{%1}',  '搬入予定日（左）')?.replace('{%2}', '搬入予定日（右）'),
};
const compareEndScheduledDeliveryDate = {
  validate(value, { endDate }) {
    return !endDate || (value.replaceAll('/','-')) >= (endDate.replaceAll('/','-'));
  },
  params: ["endDate"],
  message: ERR_0006?.replace('{%2}',  '搬入予定日（左）')?.replace('{%1}', '搬入予定日（右）'),
};


const compareStartBookableDate = {
  validate(value, { startDate }) {
    return !startDate || (value.replaceAll('/','-')) <= (startDate.replaceAll('/','-'));
  },
  params: ["startDate"],
  message: ERR_DATE_GREATER_THAN?.replace('{%1}',  '使用終了予定日（左）')?.replace('{%2}', '使用終了予定日（右）'),
};

const compareEndBookableDate = {
  validate(value, { endDate }) {
    return !endDate || (value.replaceAll('/','-')) >= (endDate.replaceAll('/','-'));
  },
  params: ["endDate"],
  message: ERR_0006?.replace('{%2}',  '使用終了予定日（左）')?.replace('{%1}', '使用終了予定日（右）'),
};


const compareStartOrderDate = {
  validate(value, { startDate }) {
    return !startDate || (value.replaceAll('/','-')) <= (startDate.replaceAll('/','-'));
  },
  params: ["startDate"],
  message: ERR_DATE_GREATER_THAN?.replace('{%1}',  '受注日（左）')?.replace('{%2}', '受注日（右）'),
};

const compareEndOrderDate = {
  validate(value, { endDate }) {
    return !endDate || (value.replaceAll('/','-')) >= (endDate.replaceAll('/','-'));
  },
  params: ["endDate"],
  message: ERR_0006?.replace('{%2}',  '受注日（左）')?.replace('{%1}', '受注日（右）'),
};

const compareStartDesiredDate = {
  validate(value, { startDate }) {
    return !startDate || (value.replaceAll('/','-')) <= (startDate.replaceAll('/','-'));
  },
  params: ["startDate"],
  message: ERR_DATE_GREATER_THAN?.replace('{%1}',  '搬入希望日（左）')?.replace('{%2}', '搬入希望日（右）'),
};

const compareEndDesiredDate = {
  validate(value, { endDate }) {
    return !endDate || (value.replaceAll('/','-')) >= (endDate.replaceAll('/','-'));
  },
  params: ["endDate"],
  message: ERR_0006?.replace('{%2}',  '搬入希望日（左）')?.replace('{%1}', '搬入希望日（右）'),
};

const compareMachineStartDate = {
  validate(value, { startDate }) {
    return !startDate || (value.replaceAll('/','-')) <= (startDate.replaceAll('/','-'));
  },
  params: ["startDate"],
  message: "使用開始日は使用終了日より前の日付を指定してください",
};

const compareStartDate = {
  validate(value, { startDate }) {
    return value >= startDate;
  },
  params: ["startDate"],
  message: "日付（開始）以降の日付を入力してください",
};

const compareEntranceRecordEndDate = {
  validate(value, { endDate }) {
    const temp = new Date(endDate);
    temp.setDate(temp.getDate() - 31);
    return value >= format(temp, "yyyy/MM/dd");
  },
  params: ["endDate"],
  message: "開始日と終了日は31日以内です",
};

const compareEntranceRecordStartDate = {
  validate(value, { startDate }) {
    const temp = new Date(startDate);
    temp.setDate(temp.getDate() + 31);
    return value <= format(temp, "yyyy/MM/dd");
  },
  params: ["startDate"],
  message: "開始日と終了日は31日以内です",
};
const compareScheduleEndDate = {
  validate(value, { endDate }) {
    const start = new Date(value);
    const end = new Date(endDate);
    return compareStartEndDate(start, end);
  },
  params: ["endDate"],
  message: "開始日と終了日は1ヶ月以内です。",
};


const compareScheduleStartDate = {
  validate(value, { startDate }) {
    const start = new Date(startDate);
    const end = new Date(value);
    return compareStartEndDate(start,end);
  },
  params: ["startDate"],
  message: "開始日と終了日は1ヶ月以内です。",
};


const compareEntranceTime = {
  validate(value, { startTime, endTime }) {
    return startTime < endTime;
  },
  params: ["startTime", "endTime"],
  message: "現場入場時間を退場時間より過去に設定してください。",
};

const compareStartHour = {
  validate(value, { startMinute, endHour, endMinute }) {
    return !(+value > +endHour || (+value == +endHour && +startMinute >= +endMinute));
  },
  params: ["startMinute", "endHour", "endMinute"],
  message: "現場入場時間を退場時間より過去に設定してください",
};

const checkStartHour = {
  validate(value, { startTime, start }) {
    return startTime >= start;
  },
  params: ["startTime", "start", "end"],
  message: "入退場時間は{start}～{end}の範囲内で設定してください",
};

const checkEndHour = {
  validate(value, { endTime, end }) {
    return endTime < end;
  },
  params: ["endTime", "start", "end"],
  message: "入退場時間は{start}～{end}の範囲内で設定してください",
};

const compareEndHour = {
  validate(value, { startHour, startMinute, endMinute }) {
    return !(+value < +startHour || (+value == +startHour && +startMinute >= +endMinute));
  },
  params: ["startHour", "startMinute", "endMinute"],
  message: "現場退場時間を入場時間より未来に設定してください",
};

const timeFormat = {
  validate(value) {
    return value.match(/^([0-4][0-9]):[0-5][0-9]$/)
  },
  message: "正しいタイムを入力してください",
};

const compareStartHourWithEndHour = {
  validate(value, { startMinute, endHour, endMinute }) {
    return !(+value < +endHour || (+value == +endHour && +startMinute <= +endMinute));
  },
  params: ["startMinute", "endHour", "endMinute"],
  message: "入退場の期間が重なっています。正しい期間を入力してください",
};

const compareStartHourWithEndHourInputext = {
  validate(value, { end }) {
    return value > end ;
  },
  params: ["end"],
  message: "入退場の期間が重なっています。正しい期間を入力してください",
};

const compareEndHourWithStartHourInputext = {
  validate(value, { start }) {
    return value < start;
  },
  params: ["start"],
  message: "入退場の期間が重なっています。正しい期間を入力してください",
};

const compareEndHourWithStartHour = {
  validate(value, { startHour, startMinute, endMinute }) {
    return !(+value > +startHour || (+value == +startHour && +startMinute <= +endMinute));
  },
  params: ["startHour", "startMinute", "endMinute"],
  message: "入退場の期間が重なっています。正しい期間を入力してください",
};

const compareWithEndDate = {
  validate(value, { endDate }) {
    return !endDate || value <= endDate;
  },
  params: ["endDate"],
  message: "使用終了日より前の日付を入力してください。"
};

const compareWithStartDate = {
  validate(value, { startDate }) {
    return !startDate || value >= startDate;
  },
  params: ["startDate"],
  message: "使用開始日以降の日付を入力してください。"
};

const checkQuantityMachine = {
  validate(value, { totalMachineOnsite }) {
    return parseInt(value) >= parseInt(totalMachineOnsite);
  },
  params: ["totalMachineOnsite"],
  message: "この数量は現場に紐づけられた機械個数以上である必要です。",
}

const checkQuantity = {
  validate(value) {
    return parseInt(value) > 0;
  },
  message: "0より大きい数値を入力してください",
}

extend("search-value-require", {
  ...required,
  message: "１文字以上入力してください"
});

const compareToCarryOutDate = {
  validate(value, { endTime }) {
    return value <= endTime || !endTime;
  },
  params: ["endTime"],
  message: "搬入予定日より前の日付を入力してください",
};

const compareToDeliveryDate = {
  validate(value, { startTime }) {
    return value >= startTime || !startTime;
  },
  params: ["startTime"],
  message: "搬出予定日以降の日付を入力してください",
};

const compareToDeliveryStartDate = {
  validate(value, { startDate }) {
    return !startDate || value >= startDate;
  },
  params: ["startDate"],
  message: "搬入予定日は開始日を終了日より過去の日付を入力してください。",
};

const compareToDeliveryEndDate = {
  validate(value, { endDate }) {
    return !endDate || value <= endDate ;
  },
  params: ["endDate"],
  message: "搬入予定日は開始日を終了日より過去の日付を入力してください。",
};

//
const compareToOrderStartDate = {
  validate(value, { startDate }) {
    return !startDate || value >= startDate;
  },
  params: ["startDate"],
  message: "発注日は開始日を終了日より過去の日付を入力してください。",
};

const compareToOrderEndDate = {
  validate(value, { endDate }) {
    return !endDate || value <= endDate ;
  },
  params: ["endDate"],
  message: "発注日は開始日を終了日より過去の日付を入力してください。",
};

const compareToEstimateStartDate = {
  validate(value, { startDate }) {
    return !startDate || value >= startDate;
  },
  params: ["startDate"],
  message: "依頼日は開始日を終了日より過去の日付を入力してください。",
};

const compareToEstimateEndDate = {
  validate(value, { endDate }) {
    return !endDate || value <= endDate ;
  },
  params: ["endDate"],
  message: "依頼日は開始日を終了日より過去の日付を入力してください。",
};

const compareToApplicationStartDate = {
  validate(value, { startDate }) {
    return !startDate || value >= startDate;
  },
  params: ["startDate"],
  message: "申請日は開始日を終了日より過去の日付を入力してください。",
};
const checkDeviceId = {
  validate(value) {
    return value.trim() !== 'sp';
  },
  message: "入力いただいたログインIDは既に使用されております。",
}

const compareToApplicationEndDate = {
  validate(value, { endDate }) {
    return !endDate || value <= endDate ;
  },
  params: ["endDate"],
  message: "申請日は開始日を終了日より過去の日付を入力してください。",
};

const compareToUseEndDate = {
  validate(value, { useEndDate }) {
    return !useEndDate || value <= useEndDate;
  },
  params: ["useEndDate"],
  message: "使用終了日より前の日付を入力してください",
};

const compareToUseStartDate = {
  validate(value, { useStartDate }) {
    return !useStartDate || value >= useStartDate;
  },
  params: ["useStartDate"],
  message: "使用開始日以降の日付を入力してください",
};

const minQuantityValue = {
  validate(value) {
    return value > 0;
  },
  message: "0より大きい数値を入力してください。",
};

const estimateOrderAmount = {
  validate(value) {
    return value > 0 && value < 1001;
  },
  message: "1以上1000以内で入力してください。",
}

const maxPrice = {
  validate(value) {
    return value < 1000000000000;
  },
  message: "999,999,999,999.99以下入力してください。",
}

const compareToCompleteDate = {
  validate(value, { expirationDate }) {
    return !expirationDate || value > expirationDate;
  },
  params: ["expirationDate"],
  message: "有効期限年月日は修了年月日より未来の日付を入力してください。",
}

const compareToExpirationDate = {
  validate(value, { compeleteDate }) {
    return !compeleteDate || value < compeleteDate ;
  },
  params: ["compeleteDate"],
  message: "有効期限年月日は修了年月日より未来の日付を入力してください。",
}

const typeNumberCoordinates = {
  validate(value) {
    let chars = String(value).split('.');
    return chars[1].length <= 8;
  },
  message: "小数点以下8桁以内で入力してください",
};

const compareToExperienceStartDate = {
  validate(value, { startMonth }) {
    if (startMonth) {
      let months = (new Date().getFullYear() - startMonth.split("/")[0]) * 12 + 1;
      months -= startMonth.split("/")[1];
      months += new Date().getMonth();
      return String(value).match(/^[0-9]*$/) && value <= months;
    } else {
      return true;
    }
  },
  params: ["startMonth"],
  message: "ブランク期間が不正です。",
};

const decimalLimit = {
  validate(value) {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    return {
      valid: value < 100000000,
    };
  },
  message: "99,999,999以下入力してください。",
};

const decimalLimitOther = {
  validate(value) {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    return {
      valid: value < 1000,
    };
  },
  message: "999.99以下入力してください。",
};

const integerOnly = {
  validate(value) {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    return {
      valid: String(value).match(/^[0-9]*$/),
    };
  },
  message: "半角数字で入力してください",
};

const money = {
  validate(value) {
    return ((value + "").replaceAll(",","")).match(/^[0-9]*$/);
  },
  message: "半角数字で入力してください"
};

const compareFieldEndDateUse = {
  validate(value, { endDate }) {
    return value <= endDate;
  },
  params: ["endDate"],
  message: "利用開始予定日は利用終了予定日より前の日付を指定してください",
};

const compareFieldStartDateUse = {
  validate(value, { startDate }) {
    return value >= startDate;
  },
  params: ["startDate"],
  message: "利用終了予定日は利用開始予定日以降の日付を指定してください",
};

const errOutOfrange = {
  validate(value, { fieldStartDate, fieldEndDate }) {
    return !(value < fieldStartDate || value > fieldEndDate);
  },
  params: ["fieldStartDate", "fieldEndDate", "textOne", "textTwo", "textThree"],
  message: "{textOne} は {textTwo}～{textThree}の範囲内にしてください",
};

const errDateGreaterThan = {
  validate(value, { startUseFieldDate }) {
    value = value + "/01";
    return !(startUseFieldDate < value);
  },
  params: ["startUseFieldDate", "textOne", "textTwo"],
  message:"{textOne}は{textTwo}より前の日付を指定してください"
};

const errMonthGreaterThan = {
  validate(value, { startUseFieldDate }) {
    value = value + "/01";
    return !(startUseFieldDate < value);
  },
  params: ["startUseFieldDate", "textOne", "textTwo"],
  message:"{textOne}は{textTwo}より前の年月を指定してください"
};


const compareFieldStartDate = {
  validate(value, { fieldEndDate }) {
    value = value + "/01";
    return fieldEndDate > value;
  },
  params: ["fieldEndDate", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}より前の年月を指定してください",
}

const errEndDateGreaterThan = {
  validate(value, { endtUseFieldDate }) {
    value = value + "/31";
    return endtUseFieldDate <= value;
  },
  params: ["endtUseFieldDate", "textOne", "textTwo"],
  message:"{textOne}は{textTwo}以降の年月を指定してください"
};

const compareFieldEndDate = {
  validate(value, { fieldStartDate }) {
    value = new Date(value + '/01');
    value = new Date(new Date(value.getFullYear(), value.getMonth() + 1, 0));
    value = format(value, "yyyy/MM/dd");
    return fieldStartDate < value;
  },
  params: ["fieldStartDate", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}以降の年月を指定してください",
}

const errDatePast = {
  validate(value, { date, isMonth }) {
    if (isMonth) {
      return !(value + "/01" < date);
    }
    return !(value < date);
  },
  params: ["date", "isMonth", "textOne"],
  message: "{textOne}は過去日付を指定できません",
}

const errMonthPast = {
  validate(value, { date, isMonth }) {
    if (isMonth) {
      return !(value + "/01" < date);
    }
    return !(value < date);
  },
  params: ["date", "isMonth", "textOne"],
  message: "{textOne}は過去年月を指定できません",
}

const errDateStart = {
  validate(value, { date }) {
    return value <= date
  },
  params: ["date", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}より前の日付を指定してください",
}


const errDateEnd = {
  validate(value, { date }) {
    return value >= date
  },
  params: ["date", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}以降の日付を指定してください",
}

const errDateZeroZeroFive = {
  validate(value, { now }) {
    return value >= now
  },
  params: ["now", "textOne"],
  message: "{textOne}は過去日付を指定できません",
}

const errDateStartAmPm = {
  validate(value, { date, am, pm }) {
    if (value === date && am > pm) {
      return value < date
    }
    return value <= date
  },
  params: ["date",  "am", "pm", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}より前の日付を指定してください",
}

const errDateEndAmPm = {
  validate(value, { date, am, pm }) {
    if (value === date && am > pm) {
      return value > date
    }
    return value >= date
  },
  params: ["date", "am", "pm", "textOne", "textTwo"],
  message: "{textOne}は{textTwo}以降の日付を指定してください",
}

const halfSizeResetpass = {
  validate(value) {
    return value.match( /^[a-zA-Z0-9]+$/);
  },
  message: "半角英数字4文字以上で入力してください",
};

const passwordRuleNew = {
  validate(value) {
    return typeof value === "string" && value.length >= 4;
  },
  message: "半角英数字4文字以上で入力してください",
};

extend("required-file", {
  ...required,
  message: "ファイルは必須項目です"
});


extend("email", email);
extend("emailphone", emailphone);
extend("password", password);
extend("repassword", repassword);
extend("passwordRetype", passwordRetype);
extend("minmax", minmax);
extend("postcode", postcode);
extend("ccusid", ccusid);
extend("kana", kana);
extend("phone", phone);
extend("passwordDiff", passwordDiff);
extend("digit", digit);
extend("number-char-symbol", numberCharSymbol);
extend("maxsize", maxsize);
extend("file-type", fileType);
extend("not_file_dynamic", notFleDynamic);
extend("minmax-pulldown", minmaxPulldown);
extend("decimal", decimal);
extend("decimalLimit", decimalLimit);
extend("decimalLimitOther", decimalLimitOther);
extend("integerOnly", integerOnly);
extend("compare-to-end-date", compareToEndDate);
extend("compare-to-start-date", compareToStartDate);
extend("compare-to-end-time", compareToEndTime);
extend("compare-to-start-time", compareToStartTime);
extend("duplicate-time", duplicateTime);
extend("compare-data-selectbox", compareDataSelectbox);
extend("compare-total-data-selectbox", compareTotalDataSelectbox);
extend("compare-person", comparePerson);
extend("halfsize", halfSize);
extend("fullsize", fullsize);
extend("before-date", beforeDateNow);
extend("choose-pass-date", choosePassDate);
extend("before-recovery-date", beforeRecoveryDate);
extend("compare-blood-pressure-max", compareBloodPressureMax);
extend("compare-blood-pressure-min", compareBloodPressureMin);
extend("compare-end-date", compareEndDate);
extend("compare-start-date", compareStartDate);
extend("duplicate-construction-name", duplicateConstructionName);
extend("compare-entrance-time", compareEntranceTime);
extend("compare-start-hour", compareStartHour);
extend("compare-end-hour", compareEndHour);
extend("compare-start-hour-with-end-hour", compareStartHourWithEndHour);
extend("compare-end-hour-with-start-hour", compareEndHourWithStartHour);
extend("check-start-hour", checkStartHour);
extend("check-end-hour", checkEndHour);
extend("is-past-date", isPastDate);
extend("time-format", timeFormat);
extend("compare-entrance-record-start-date", compareEntranceRecordStartDate);
extend("compare-entrance-record-end-date", compareEntranceRecordEndDate);
extend("compare-entrance-record-end-date-text", compareStartHourWithEndHourInputext);
extend("compare-entrance-record-start-date-text", compareEndHourWithStartHourInputext);
extend("duplicate-time-zone-name", duplicateTimeZoneName);
extend("half-size-password", halfSize_device);
extend("check-quantity-machine", checkQuantityMachine);
extend("check-quantity", checkQuantity);
extend('compare-end-date-regis-face', {
  ...compareEndDate,
  message: "入場開始日（開始））以降の日付を入力してください",
});
extend('compare-start-date-regis-face', {
  ...compareStartDate,
  message: "入場開始日（終了）より前の日付を入力してください",
});
extend("duplicate-device-id", duplicateDeviceId);
extend("check-device-id", checkDeviceId);
extend("compare-with-end-date",compareWithEndDate);
extend("compare-with-start-date",compareWithStartDate);

extend('compare-machine-used-end-date', compareMachineEndDate);
extend('compare-end-scheduled-delivery-date', compareEndScheduledDeliveryDate);
extend('compare-start-scheduled-delivery-date', compareStartScheduledDeliveryDate);
extend('compare-start-bookable-date', compareStartBookableDate);
extend('compare-end-bookable-date', compareEndBookableDate);
extend('compare-start-order-date', compareStartOrderDate);
extend('compare-end-order-date', compareEndOrderDate);
extend('compare-start-desired-date', compareStartDesiredDate);
extend('compare-end-desired-date', compareEndDesiredDate);
extend('compare-machine-used-start-date', compareMachineStartDate);
extend("compare-to-carry-out-date",compareToCarryOutDate);
extend("compare-to-delivery-date",compareToDeliveryDate);
extend("compare-to-delivery-start-date", compareToDeliveryStartDate);
extend("compare-to-delivery-end-date", compareToDeliveryEndDate);

extend("compare-to-application-start-date", compareToApplicationStartDate);
extend("compare-to-application-end-date", compareToApplicationEndDate);
extend("compare-to-estimate-start-date", compareToEstimateStartDate);
extend("compare-to-estimate-end-date", compareToEstimateEndDate);
extend("compare-to-order-start-date", compareToOrderStartDate);
extend("compare-to-order-end-date", compareToOrderEndDate);
extend("compare-to-use-end-date", compareToUseEndDate);
extend("compare-to-use-start-date", compareToUseStartDate);
extend('min-quantity-value', minQuantityValue);
extend("estimate-order-amount",estimateOrderAmount);
extend("max-price",maxPrice);
extend("compare-to-expiration-date", compareToExpirationDate);
extend("compare-to-complete-date", compareToCompleteDate);
extend("type-number-coordinates", typeNumberCoordinates);
extend("compare-to-experience-start-date",compareToExperienceStartDate);
extend("compare-schedule-start-date", compareScheduleStartDate);
extend("compare-schedule-end-date", compareScheduleEndDate);
extend("money", money);
extend('compare-field-end-date-use', compareFieldEndDateUse);
extend('compare-field-start-date-use', compareFieldStartDateUse);
extend('err-out-of-range', errOutOfrange);
extend('err-date-greater-range', errDateGreaterThan);
extend('err-month-greater-range', errMonthGreaterThan);
extend('err-end-date-greater-range', errEndDateGreaterThan);
extend('compare-field-start-date', compareFieldStartDate);
extend('compare-field-end-date', compareFieldEndDate);
extend('err-date-past', errDatePast);
extend('err-month-past', errMonthPast);
extend('err-start-date', errDateStart);
extend('err-end-date', errDateEnd);
extend('err-005', errDateZeroZeroFive);
extend('compare-min', compareMin);
extend('compare-max', compareMax);
extend('compare-float-min', compareFloatMin);
extend('compare-float-max', compareFloatMax);
extend('err-start-date-am-pm', errDateStartAmPm);
extend('err-end-date-am-pm', errDateEndAmPm);
extend('halfSize-resetpass', halfSizeResetpass);
extend('password-rule-new', passwordRuleNew);
// test用にexport
export {
  email,
  emailphone,
  password,
  repassword,
  minmax,
  postcode,
  kana,
  phone,
  passwordDiff,
  digit,
  numberCharSymbol,
  maxsize,
  fileType,
  notFleDynamic,
  minmaxPulldown,
  decimal,
  decimalLimit,
  integerOnly,
  compareToStartDate,
  compareToEndDate,
  compareToEndTime,
  compareToStartTime,
  duplicateTime,
  compareDataSelectbox,
  compareTotalDataSelectbox,
  comparePerson,
  halfSize,
  fullsize,
  beforeDateNow,
  choosePassDate,
  beforeRecoveryDate,
  compareStartDate,
  compareEndDate,
  compareEntranceTime,
  duplicateConstructionName,
  compareStartHour,
  compareEndHour,
  compareStartHourWithEndHour,
  compareEndHourWithStartHour,
  checkStartHour,
  checkEndHour,
  isPastDate,
  timeFormat,
  compareEntranceRecordStartDate,
  compareEntranceRecordEndDate,
  compareStartHourWithEndHourInputext,
  compareEndHourWithStartHourInputext,
  duplicateTimeZoneName,
  halfSize_device,
  duplicateDeviceId,
  compareWithEndDate,
  compareWithStartDate,
  checkQuantityMachine,
  checkQuantity,
  compareMachineEndDate,
  compareMachineStartDate,
  compareToDeliveryDate,
  compareToCarryOutDate,
  compareToDeliveryStartDate,
  compareToDeliveryEndDate,
  compareToOrderEndDate,
  compareToOrderStartDate,
  compareToEstimateEndDate,
  compareToEstimateStartDate,
  compareToApplicationEndDate,
  compareToApplicationStartDate,
  compareToUseEndDate,
  compareToUseStartDate,
  minQuantityValue,
  estimateOrderAmount,
  compareToExpirationDate,
  compareToCompleteDate,
  typeNumberCoordinates,
  compareToExperienceStartDate,
  compareScheduleStartDate,
  compareScheduleEndDate,
  money,
  compareFieldEndDateUse,
  compareFieldStartDateUse,
  errOutOfrange,
  errDateGreaterThan,
  errMonthGreaterThan,
  compareFieldStartDate,
  errEndDateGreaterThan,
  compareFieldEndDate,
  errDatePast,
  errMonthPast,
  errDateStart,
  errDateEnd,
  errDateZeroZeroFive,
  compareEndScheduledDeliveryDate,
  compareStartScheduledDeliveryDate,
  compareStartBookableDate,
  compareEndBookableDate,
  compareMin,
  compareMax,
  compareStartOrderDate,
  compareEndOrderDate,
  compareStartDesiredDate,
  compareEndDesiredDate,
  compareFloatMin,
  compareFloatMax,
  errDateEndAmPm,
  errDateStartAmPm,
  halfSizeResetpass,
  passwordRuleNew
};
