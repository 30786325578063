<template>
  <div>
    <DefaultLayout class="field-user-custom-class-css">
      <template #mainHeader>
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            ref="myTableLayoutTab"
          >
            <template #tableHeader="{ updateHeader }" class="pa-0">
              <v-row>
                <v-col>
                  <TableHeader
                    :key="keyHeader"
                    ref="tableHeader"
                    :pageTitle="PAGE_TITLE"
                    :multiRemoveStatus="!isShowDeleteFieldGroupButton"
                    :updateHeader="updateHeader"
                    :searchFieldGroupParams="searchFieldGroupParams"
                    :isDelete="false"
                    style="padding-left: 16px"
                    @openItemForm="openNewItemForm"
                  >
                    <SearchFormWrapper class="p-0">
                      <Label label="会社名" class="title-input-item">
                        <div style="min-width: 80px">
                          <InputText
                            name="companyName"
                            :editable="true"
                            :values="searchFieldGroupParams"
                            @onInput="onChangeFieldGroupSearchParams"
                          />
                        </div>
                      </Label>
                      <Label label="氏名" class="title-input-item">
                        <div style="min-width: 80px">
                          <InputText
                            name="userName"
                            :editable="true"
                            :values="searchFieldGroupParams"
                            @onInput="onChangeFieldGroupSearchParams"
                          />
                        </div>
                      </Label>
                      <Label label="メールアドレス" class="title-input-item">
                        <div style="min-width: 80px">
                          <InputText
                            name="email"
                            :editable="true"
                            :values="searchFieldGroupParams"
                            @onInput="onChangeFieldGroupSearchParams"
                          />
                        </div>
                      </Label>
                      <Label label="携帯番号" class="title-input-item">
                        <div style="min-width: 80px">
                          <InputText
                            name="phone"
                            :editable="true"
                            :values="searchFieldGroupParams"
                            @onInput="onChangeFieldGroupSearchParams"
                          />
                        </div>
                      </Label>
                      <Label label="種別" class="title-input-item role-method">
                        <TabSelect
                          name="userType"
                          :items="USER_TYPE_METHOD"
                          :editable="true"
                          :values="searchFieldGroupParams"
                          @onInput="onChangeFieldGroupSearchParams"
                        />
                      </Label>
                      <v-spacer></v-spacer>
                      <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                        検索
                      </v-btn>
                    </SearchFormWrapper>
                  </TableHeader>
                </v-col>
              </v-row>
              <TableSortWrapper>
                <!--
                  (共通) ソート
                  ソート項目、ソート順、表示件数の選択、総件数の表示
                -->
                <TableSort
                  :key="keyHeader + 1"
                  :values="searchFieldGroupParams"
                  :sort_items="ALL_USER_LIST_SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_SIZE_OPTIONS"
                  :page_counts_value="searchFieldGroupParams.pageSize"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchFieldGroupParams.total_itemApprove"
                  @onInput="onChangeSortFieldGroupParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>
            </template>
            <template #tableBody="{ tableHeight }">
              <v-data-table
                item-key="id"
                v-model="selectedItems"
                :headers="ALL_USER_TABLE_LABELS"
                :items="enrichedDessertsUser"
                :items-per-page="searchFieldGroupParams.pageSize"
                :height="hideFooterAppRove ? tableHeight - 55 : tableHeight - 45"
                fixed-header
                show-select
                hide-default-footer
                disable-sort
                class="elevation-0 v-data-table__wrapper"
                sort-by="updatedAt"
                :noDataText="NO_DATA_MESSAGE"
                :item-class="itemRowBackground"
                style="box-shadow: none !important;"
                @update:searchFieldGroupParams.currentPage="$vuetify.goTo(0)"
                @click:row="openItemEditForm"
                :key=searchFieldGroupParams.currentPage
              >
                <template v-slot:[`item.create_user`]="{ item }">
                  {{ getUserName(item, 'create_user') }}
                </template>
                <template v-slot:[`item.update_user`]="{ item }">
                  {{ getUserName(item, 'update_user') }}
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <Pagination
                :current="searchFieldGroupParams.currentPageApprove"
                :total="searchFieldGroupParams.totalPageApprove"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0"/>
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="popups.isShowErorDialog">
      <ErrorDialog
        @yes="closeErrorDialog"
        @close="closeErrorDialog"
        :text="textDialogError"
        title="エラー"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowUploadForm">
      <UploadFieldGroupsInfoDialog
        @close="closeUploadDialog"
        @yes="openConfirmUploadDialog"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowToDeleteFieldGroupDialog">
      <ConfirmRemoveDialog
        v-model="dialogDel"
        :title="WRN_0001.TITLE"
        :text1="WRN_0001.TEXT1"
        :text2="WRN_0001.TEXT2"
        :warning="true"
        @close="closeDeleteFieldGroupDialog"
        @yes="deleteFieldGroups"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowToStartDialog">
      <ConfirmUpdateUseStatus
        title="利用再開"
        text1="選択したユーザーの現場連携を再開します。"
        text2="よろしいですか？"
        submitText="再開"
        @close="closeUpdateUseStatusDialog"
        @yes="saveUseStatusToStartData"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowToEndDialog">
      <ConfirmUpdateUseStatus
        title="利用解除"
        text1="選択したユーザーの現場連携を解除します。"
        text2="よろしいですか？"
        submitText="解除"
        @close="closeUpdateUseStatusDialog"
        @yes="saveUseStatusToEndData"
        warning
      />
    </Popup>

    <Popup :dialog="popups.isShowFieldGroupForm">
      <User
        :flag="true"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :actions="actions"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeFieldGroupForm"
        @getListUser="getItems"
        @reloadItem="reloadItem"
        :key="isResetForm"
        :company="company"
      />
    </Popup>
    <Popup :dialog="popups.isShowFieldGroupUserForm">
      <FieldGroupUserForm
        :fieldId=this.fieldId
        :item="editedItem"
        :isNewItem="isNewItem"
        :actions="actions"
        @getItems="getFieldGroupUsers"
        @closeFieldGroupUserForm="closeFieldGroupUserForm"
      />
    </Popup>

    <!--confirm upload file-->
    <Popup width="480px" :dialog="popups.isShowConfirmUploadDialog">
      <ConfirmBulkUploadDialog
        @close="closeConfirmUploadDialog"
        @yes="submitUploadInfo"
        title="所属を一括で登録"
        :content="[
            '時間がかかる場合がありますので、しばらくお待ちください。',
        ]"
        primary
        nameBtnSave="登録"
      />

    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import {Store} from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import TabSelect from "@/components/forms/fieldUsers/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./popup/ConfirmRemoveDialog";
import Confirm from "./popup/Confirm.vue";
import FieldUserHeader from "./components/FieldUserHeader";
import ConfirmUpdateUseStatus from "@/components/forms/fieldGroups/Popup/ConfirmUpdateUseStatusForm.vue";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import User from "@/components/forms/user/components/User.vue";
import FieldGroupForm from "@/components/forms/fieldGroups/FieldGroupForm"
import _ from "lodash";
import FieldGroupUserForm from "@/components/forms/fieldGroupUsers/FieldGroupUserForm"
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import { WRN_0001 } from "@/constants/MESSAGE";
import { HEADER_MENU_ITEMS_ADMIN } from "@/constants/GLOBALHEADER";
import {
  COMPANY_RENTAL,
  COMPANY_SCENE,
  ROLE_SUPERVISOR,
  NO_DATA_MESSAGE,
  TABLE_SORT_ORDERS,
  TABLES_PER_PAGE
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  ALL_USER_LIST_SORT_ITEMS,
  FIELD_GROUP_USER_LIST_SORT_ITEMS,
  FIELD_GROUP_TAB_SELECT,
  FIRST_LOAD,
  MAIN_CONTRACTOR_ROLE,
  PARTNER_RESERVER_ROLE,
  PARTNER_WORKER_ROLE,
  RENTAL_COMPANY_USER_ROLE,
  ROLE_NAME,
  FORCED_MOVE,
  FORCED_MOVE_VIEW,
  ROLE_TYPE,
  FIELD_GROUP_USER_ROLE_TYPE,
  FIELD_GROUP_PARTNER_USER_ROLE_TYPE,
  USE_STATUS_TYPE,
  USER_TYPES,
  ALL_USER_TABLE_LABELS,
  FIELD_GROUP_TABLE_LABELS_PARTNER,
  USER_TABLE_NOT_APPROVE_LABELS,
  RECEIPT_ALLOCATION_VIEW,
  RECEIPT_ALLOCATION
} from "@/constants/USERS"
import {
  HEADER_MENU_ITEMS_FIELD_PORTAL,
} from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import UploadFieldGroupsInfoDialog from "./components/UploadFieldGroupsInfoDialog.vue";
import {convertName, dateFormat} from "@/common/helper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Loading from "@/components/loading/Loading";
import ConfirmBulkUploadDialog from "@/views/fieldUser/popup/ConfirmBulkUploadDialog";
import ErrorDialog from "@/components/dialog/ErrorDialog";
import {ValidationProvider, ValidationObserver} from "vee-validate";
//タイトル
let PAGE_TITLE = "ユーザー";

//１ページあたりのテーブル件数オプション
const PAGE_SIZE_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
//１ページあたりのテーブル件数
const PAGE_SIZE = 25;
const TAB_LIST_FIELD_GROUP = 0;
const TAB_LIST_FIELD_GROUP_USER = 1;
const TAB_NOT_APPROVE_USER = 2

const USER_STORE = "MasterUsers";
const FIELD_GROUP_USER_STORE = "FieldGroupUsers";
export default {

  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      WRN_0001,
      PAGE_TITLE,
      SORT_ORDERS,
      PAGE_SIZE_OPTIONS,
      ROLE_TYPE,
      NO_DATA_MESSAGE,
      FIELD_GROUP_TAB_SELECT,
      defaultValues: {
        name: '',
        leader_name: '',
      },
      ALL_USER_TABLE_LABELS,
      USER_TABLE_NOT_APPROVE_LABELS,
      FIELD_GROUP_TABLE_LABELS_PARTNER,
      USER_LABELS: {},
      ALL_USER_LIST_SORT_ITEMS,
      FIELD_GROUP_USER_LIST_SORT_ITEMS,
      ROLE_NAME,
      USER_TYPES,
      FORCED_MOVE,
      FORCED_MOVE_VIEW,
      MAIN_CONTRACTOR_ROLE,
      PARTNER_RESERVER_ROLE,
      PARTNER_WORKER_ROLE,
      RENTAL_COMPANY_USER_ROLE,
      FIRST_LOAD,
      RECEIPT_ALLOCATION_VIEW,
      RECEIPT_ALLOCATION,
      /**
       * (共通)
       * 一覧データ
       */
      idFieldLogin: 0,
      fieldId: 0,
      userLogin: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.id,
      actions: null,
      companyId: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.company_id,
      totalFieldUserInfo: 0,
      totalFieldUserNotApprove: 0,
      isDisable: true,
      isShowBulkAddUsers: false,
      items: [],
      fieldGroupUsers: [],
      selectedNotApproveItems: [],
      selectedItems: [],
      isShowDeleteFieldGroupButton: false,
      isShowUpdateUseStatusToStartButton: false,
      isShowUpdateUseStatusToEndButton: false,
      changeSortBy: 0,
      searchFieldGroupParams: {
        companyName: "",
        userName: "",
        email: "",
        phone: "",
        currentPage: 1,
        pageSize: PAGE_SIZE,
        sortValue: "company_name",
        orderBy: "asc"
      },
      searchFieldGroupUserParams: {
        userName: "",
        phoneNumber: "",
        groupName: "",
        leaderName: "",
        role: "",
        usageStatus: "",
        currentPage: 1,
        pageSize: PAGE_SIZE,
        sortValue: "name",
        orderBy: "asc"
      },
      firstLoad: 0,
      firstLoadNotApprove: 0,
      fixDataApprove: false,
      isNewItem: false,
      popups: {
        // 削除確認ダイアログ表示
        isShowToDeleteFieldGroupDialog: false,
        isShowToStartDialog: false,
        isShowToEndDialog: false,
        isShowUploadForm: false,
        isShowRemoveDialog: false,
        isShowConfirmDialog: false,
        isShowRemoveNotApproveDialog: false,
        isShowFieldGroupForm: false,
        isShowFieldGroupUserForm: false,
        isShowConfirmUploadDialog: false,
        isShowErorDialog: false
      },
      isShowButtonDelete: true,
      approveCheck: true,
      flagUserRentalCompany: true,
      leaderTeam: [],
      leaderTeamIds: [],
      allUsers: [],
      editedItem: {},
      COMPANY_SCENE,
      COMPANY_RENTAL,
      ROLE_SUPERVISOR,
      TAB_LIST_FIELD_GROUP,
      TAB_LIST_FIELD_GROUP_USER,
      TAB_NOT_APPROVE_USER,
      isLoading: false,
      hideFooterAppRove: true,
      hideFooterNotAppRove: true,
      textDialogError: "",
      fileUpload: [],
      countNotApproveItems: 0,
      keyHeader: 0,
    }
  },
  components: {
    CompanyHeader,
    FieldUserHeader,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TabSelect,
    Popup,
    UploadFieldGroupsInfoDialog,
    ConfirmRemoveDialog,
    Confirm,
    TableSortWrapper,
    TableSort,
    InputText,
    Label,
    User,
    FieldGroupForm,
    FieldGroupUserForm,
    convertName,
    dateFormat,
    Select,
    SitePortalHeader,
    Loading,
    ConfirmUpdateUseStatus,
    ConfirmBulkUploadDialog,
    ErrorDialog,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_ADMIN.USERS.id,
    });

    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.USER_LIST.id,
    });

    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    }

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.$watch(
      () => [
        //Store.getters[`${USER_STORE}/getCountFieldUserNotApprove`],
      ],
      (data) => {
        this.countNotApproveItems = data[0];
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.getUserLabel();
    this.getItems();
    //this.getFieldGroupUsers();
    this.$watch(
      () => [
        Store.getters[`${USER_STORE}/getData`],
        Store.getters[`${USER_STORE}/getPagination`],
        Store.getters[`${USER_STORE}/leaderIds`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((item) => ({
          ...item
        }));
        let searchFieldGroupParams = {...this.searchFieldGroupParams};
        searchFieldGroupParams.totalPage = data[1].total;
        searchFieldGroupParams.currentPage = data[1].current;
        searchFieldGroupParams.total_item = data[1].total_item;
        searchFieldGroupParams.totalPageApprove = data[1].total;
        searchFieldGroupParams.currentPageApprove = data[1].current;
        searchFieldGroupParams.total_itemApprove = data[1].total_item;
        this.searchFieldGroupParams = searchFieldGroupParams;
        this.hideFooterAppRove = searchFieldGroupParams.total_itemApprove <= searchFieldGroupParams.pageSize;
        const idFieldLogin = _items.filter((item) => item.user_id === this.userLogin);
        if (idFieldLogin.length) {
          this.idFieldLogin = idFieldLogin[0].id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        Store.getters[`${FIELD_GROUP_USER_STORE}/getData`],
        Store.getters[`${FIELD_GROUP_USER_STORE}/getPagination`],
        Store.getters[`${FIELD_GROUP_USER_STORE}/leaderIds`],
        Store.getters[`${USER_STORE}/getDataNotApprove`],
        Store.getters[`${USER_STORE}/getPaginationNotApprove`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.fieldGroupUsers = _items.map((item) => ({
          ...item
        }));
        let searchFieldGroupUserParams = {...this.searchFieldGroupUserParams};
        searchFieldGroupUserParams.totalPage = data[1].total;
        searchFieldGroupUserParams.currentPage = data[1].current;
        searchFieldGroupUserParams.total_item = data[1].total_item;
        searchFieldGroupUserParams.totalPageNotApprove = data[1].total;
        searchFieldGroupUserParams.currentPageNotApprove = data[1].current;
        searchFieldGroupUserParams.total_itemNotApprove = data[1].total_item;
        this.searchFieldGroupUserParams = searchFieldGroupUserParams;
        if (this.firstLoadNotApprove === FIRST_LOAD) {
          this.totalFieldUserNotApprove = searchFieldGroupUserParams.total_itemNotApprove;
        }
        this.hideFooterNotAppRove = searchFieldGroupUserParams.total_itemNotApprove <= searchFieldGroupUserParams.pageSize;
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.$watch(
      () => Store.getters[`${USER_STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`${FIELD_GROUP_USER_STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    if (this.roleLogin === this.COMPANY_RENTAL) {
      //this.getListPartnerLeaderInField();
      this.$watch(
        () => Store.getters[`${USER_STORE}/getLeaderIds`],
        (data) => {
          this.leaderTeam = data;
          this.leaderTeam = this.leaderTeam.map((item) => {
            return {
              id: item.id,
              name: this.convertFullName(item),
            };
          });
          this.leaderTeam.unshift({
            id: "",
            name: ""
          });
        },
        {
          immediate: true,
          deep: true,
        }
      );
    }
  },
  watch: {
    selected(listSelect) {
      this.ids = [];
      listSelect.forEach((item) => {
        this.ids.push(item.field_machine_id);
      });
      this.isDisable = listSelect.length == 0;
    },
    selectedNotApproveItems: function (newValue) {
      if (newValue.length) {
        this.approveCheck = false;
      } else {
        this.approveCheck = true;
      }
    },
    selectedItems() {
      if (this.selectedItems.length > 0) {
        this.isShowDeleteFieldGroupButton = true;
        this.isShowUpdateUseStatusToStartButton = true;
        this.isShowUpdateUseStatusToEndButton = true;
      } else {
        this.isShowDeleteFieldGroupButton = false;
        this.isShowUpdateUseStatusToStartButton = false;
        this.isShowUpdateUseStatusToEndButton = false;
      }
    },
  },
  computed: {
    enrichedDessertsUser() {
      return this.items
        .filter((item) => item.admin_flg !== 1)
        .map((item) => ({
          ...item,
        }));
    },
    enrichedDesserts() {
      return this.fieldGroupUsers.map((item) => ({
        ...item,
      }));
    },
    TAB_SELECT() {
      return Object.keys(FIELD_GROUP_TAB_SELECT).map((key) => {
        return FIELD_GROUP_TAB_SELECT[key];
      });
    },
    fieldGroupListApiParams() {
      return {
        company_name: this.searchFieldGroupParams.companyName,
        user_name: this.searchFieldGroupParams.userName,
        email: this.searchFieldGroupParams.email,
        phone: this.searchFieldGroupParams.phone,
        user_type: this.searchFieldGroupParams.userType,
        page: this.searchFieldGroupParams.currentPage,
        size: this.searchFieldGroupParams.pageSize,
        sort: this.searchFieldGroupParams.sortValue,
        order: this.searchFieldGroupParams.orderBy,
      };
    },
    fieldGroupUserListApiParams() {
      let roleParam = this.searchFieldGroupUserParams.role;
      if (this.roleLogin == PARTNER_RESERVER_ROLE) {
        roleParam = this.searchFieldGroupUserParams.role.length === 0 ? [2, 3] : this.searchFieldGroupUserParams.role;
      }
      return {
        field_group_id: this.searchFieldGroupUserParams.fieldGroupId,
        field_user_id: this.searchFieldGroupUserParams.fieldUserId,
        user_name: this.searchFieldGroupUserParams.userName,
        phone_number: this.searchFieldGroupUserParams.phoneNumber,
        group_name: this.searchFieldGroupUserParams.groupName,
        leader_name: this.searchFieldGroupUserParams.leaderName,
        role: roleParam,
        usage_status: this.searchFieldGroupUserParams.usageStatus,
        page: this.searchFieldGroupUserParams.currentPage,
        size: this.searchFieldGroupUserParams.pageSize,
        sort: this.searchFieldGroupUserParams.sortValue,
        order: this.searchFieldGroupUserParams.orderBy,
      };
    },
    ROLE_METHOD() {
      return Object.keys(ROLE_TYPE).map((key) => {
        return ROLE_TYPE[key];
      });
    },
    USER_TYPE_METHOD() {
      return Object.keys(USER_TYPES).map((key) => {
        return USER_TYPES[key];
      });
    },
    USE_STATUS_TYPE_METHOD() {
      return Object.keys(USE_STATUS_TYPE).map((key) => {
        return USE_STATUS_TYPE[key];
      });
    },
    FIELD_GROUP_USER_ROLE() {
      return Object.keys(FIELD_GROUP_USER_ROLE_TYPE).map((key) => {
        return FIELD_GROUP_USER_ROLE_TYPE[key];
      });
    },
    FIELD_GROUP_PARTNER_USER_ROLE_TYPE() {
      return Object.keys(FIELD_GROUP_PARTNER_USER_ROLE_TYPE).map((key) => {
        return FIELD_GROUP_PARTNER_USER_ROLE_TYPE[key];
      });
    },
  },

  methods: {
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    openNewItemForm() {
      this.actions = 'create';
      let editedItem = {
        company_id: null,
        company_name: null,
      };
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowFieldGroupForm = true;
    },
    async openItemEditForm(item) {
      this.actions = "edit"
      let params = { id: item.id}
      const siteInfo = await Store.dispatch(
        `${USER_STORE}/getUserInfoById`,
        {params}
      );
      if (siteInfo?.data?.contents?.entries) {
        this.editedItem = {...siteInfo?.data?.contents?.entries};
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
      this.fieldId = item.field_id;
      this.openFieldGroupForm();
    },

    async openItemGroupUserForm(item) {
      this.actions = "edit"
      let params = { id: item.field_group_user_id}
      const siteInfo = await Store.dispatch(
        `${FIELD_GROUP_USER_STORE}/getDetail`,
        {params}
      );
      // Show Detail Form
      this.openFieldGroupUserForm();
      if (siteInfo?.data?.contents?.entries) {
        this.editedItem = {...siteInfo?.data?.contents?.entries};
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },

    openDeleteFieldGroupDialog() {
      this.popups.isShowToDeleteFieldGroupDialog = true;
    },
    openUpdateUseStatusToStartDialog() {
      this.popups.isShowToStartDialog = true;
    },
    openUpdateUseStatusToEndDialog() {
      this.popups.isShowToEndDialog = true;
    },
    closeDeleteFieldGroupDialog() {
      this.popups.isShowToDeleteFieldGroupDialog = false;
    },
    closeUpdateUseStatusDialog() {
      this.popups.isShowToStartDialog = false;
      this.popups.isShowToEndDialog = false;
    },
    convertFieldEnDate(item) {
      return item.replaceAll("-", "/").slice(0, 16);
    },
    openUploadDialog() {
      this.popups.isShowUploadForm = true;
    },
    closeUploadDialog() {
      this.textDialogError = "";
      this.fileUpload = [];
      this.popups.isShowUploadForm = false;
    },
    openFieldGroupForm() {
      this.popups.isShowFieldGroupForm = true;
    },
    openCreateItemForm() {
      this.actions = "create"
      this.isNewItem = true;
      this.editedItem = this.defaultValues;
      this.openFieldGroupForm();
    },
    openFieldGroupUserForm() {
      this.popups.isShowFieldGroupUserForm = true;
    },
    closeFieldGroupForm() {
      this.popups.isShowFieldGroupForm = false;
    },
    closeFieldGroupUserForm() {
      this.popups.isShowFieldGroupUserForm = false;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
    },
    openConfirmDialog() {
      this.popups.isShowConfirmDialog = true;
    },
    onClickBtnCancelShowConfirm() {
      this.popups.isShowConfirmDialog = false;
    },
    openRemoveNotApproveDialog() {
      this.popups.isShowRemoveNotApproveDialog = true;
    },
    closeRemoveNotApproveDialog() {
      this.popups.isShowRemoveNotApproveDialog = false;
    },
    closeConfirmUploadDialog() {
      this.popups.isShowConfirmUploadDialog = false;
    },
    closeErrorDialog() {
      this.popups.isShowErorDialog = false;
    },
    openConfirmUploadDialog(param) {
      this.fileUpload = param;
      this.popups.isShowConfirmUploadDialog = true;
    },
    async deleteFieldGroups(isShowLoading = true) {
      const field_group_ids = this.selectedItems.map(item => item.field_group_id);
      const result = await Store.dispatch(`${USER_STORE}/deleteFieldGroupByIds`, { field_group_ids }, isShowLoading);
      if (!result.hasError) {
        this.searchFieldGroupParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeDeleteFieldGroupDialog();
        //成功したら値を更新
        await this.getItems();
        await Store.dispatch("Toast/show", {
          status: 200,
          message: '更新しました。',
        });
        this.selectedItems = [];
      }
    },
    async saveUseStatusToStartData(isShowLoading = true) {
      const field_group_users = this.selectedItems
        .map((item) => ({
          id: item.field_group_user_id,
          usage_status: 1,
          role: item.role
        }));

      const result = await Store.dispatch(`${FIELD_GROUP_USER_STORE}/bulkUpdateFieldGroupUsers`, {field_group_users}, isShowLoading);
      if (!result.hasError) {
        this.selectedNotApproveItems = [];
        this.searchFieldGroupParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeUpdateUseStatusDialog();
        //成功したら値を更新
        await this.getFieldGroupUsers();
        await Store.dispatch("Toast/show", {
          status: 200,
          message: '更新しました。',
        });
        this.selectedItems = [];
      }
    },
    async saveUseStatusToEndData(isShowLoading = true) {
      const field_group_users = this.selectedItems
        .map((item) => ({
          id: item.field_group_user_id,
          usage_status: 0,
          role: item.role
        }));

      const result = await Store.dispatch(`${FIELD_GROUP_USER_STORE}/bulkUpdateFieldGroupUsers`, {field_group_users}, isShowLoading);
      if (!result.hasError) {
        this.selectedNotApproveItems = [];
        this.searchFieldGroupParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeUpdateUseStatusDialog();
        //成功したら値を更新
        await this.getFieldGroupUsers();
        await Store.dispatch("Toast/show", {
          status: 200,
          message: '更新しました。',
        });
        this.selectedItems = [];
      }
    },
    async updateTeamSuccess() {
      this.firstLoad = FIRST_LOAD;
      await this.getItems();
    },
    async deleteNotApproveItems(isShowLoading = true) {
      const ids = this.selectedNotApproveItems.map((items) => items.id);
      this.closeRemoveNotApproveDialog();
      const result = await Store.dispatch(`${USER_STORE}/deleteFieldUserByIds`, {ids}, isShowLoading);
      if (!result.hasError) {
        this.selectedNotApproveItems = [];
        this.searchFieldGroupParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
    },
    // データ取得
    getTabData() {
    //  this.getFieldGroupUsers();
    },

    async getItems(isShowLoading = true) {
      if (Array.isArray(this.fieldGroupListApiParams.user_type)) {
        this.fieldGroupListApiParams.user_type = this.fieldGroupListApiParams.user_type.join(',');
      }
      const result = await Store.dispatch(`${USER_STORE}/getListAllUserInfo`, {params: this.fieldGroupListApiParams}, isShowLoading);
      if (result.hasError === true) {
        this.items = [];
        this.totalFieldUserInfo = 0;
      }
      this.selectedItems = [];
    },
    async getFieldGroupUsers(isShowLoading = true) {
      if (Array.isArray(this.fieldGroupUserListApiParams.role)) {
        this.fieldGroupUserListApiParams.role = this.fieldGroupUserListApiParams.role.join(',');
      }
      if (Array.isArray(this.fieldGroupUserListApiParams.usage_status)) {
        this.fieldGroupUserListApiParams.usage_status = this.fieldGroupUserListApiParams.usage_status.join(',');
      }
      if (this.roleLogin == 2) {
        this.fieldGroupUserListApiParams.only_myteam = 1;
      } else {
        this.fieldGroupUserListApiParams.only_myteam = 0;
      }

      const result = await Store.dispatch(`${FIELD_GROUP_USER_STORE}/getListFieldGroupUsers`, {params: this.fieldGroupUserListApiParams}, isShowLoading);
      if (result.hasError === true) {
        this.fieldGroupUsers = [];
        this.totalFieldUserNotApprove = 0;
      }
      this.selectedNotApproveItems = [];
    },
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const userIds = this.selectedItems.map((items) => items.user_id);
      const fieldId = this.fieldId;
      this.closeRemoveDialog();
      const result = await Store.dispatch(`${USER_STORE}/deleteFieldUserByIds`, {ids, userIds, fieldId});
      let checkDeleteThisMySelf = [];
      if (!result.hasError) {
        this.selectedItems = [];
        this.searchFieldGroupParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
          checkDeleteThisMySelf = result?.data?.contents?.ids.filter((item) => item === this.idFieldLogin);
          if (checkDeleteThisMySelf.length) {
            await this.$router.push('master/fields')
          }
        }
      }
      if (!checkDeleteThisMySelf.length) {
        await this.getItems();
      }
    },

    pageUpdate(n) {
      let searchFieldGroupParams = {...this.searchFieldGroupParams};
      searchFieldGroupParams.currentPage = n;
      searchFieldGroupParams.currentPageApprove = n;
      this.searchFieldGroupParams = searchFieldGroupParams;
      this.getItems();
    },
    pageUpdateFgu(n) {
      let searchFieldGroupUserParams = {...this.searchFieldGroupUserParams};
      searchFieldGroupUserParams.currentPage = n;
      searchFieldGroupUserParams.currentPageNotApprove = n;
      this.searchFieldGroupUserParams = searchFieldGroupUserParams;
      this.getFieldGroupUsers();
    },
    convertName(str, length) {
      return convertName(str, length);
    },
    getUserName(item, key) {
      if (key == 'create_user') {
        return item.create_user ? `${item.create_user.name_sei} ${item.create_user.name_mei}` : '';
      } else if (key == 'update_user') {
        return item.update_user ? `${item.update_user.name_sei} ${item.update_user.name_mei}` : '';
      } else {
        return `${item[key].name_sei} ${item[key].name_mei}`;
      }
    },
    convertFullName(item, leader = false) {
      if (leader) {
        // eslint-disable-next-line no-irregular-whitespace
        return `${item.leader_name_sei}　${item.leader_name_mei}`;
      }
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`;
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeFieldGroupSearchParams({name, value}) {
      let searchFieldGroupParams = {...this.searchFieldGroupParams};
      searchFieldGroupParams[name] = value;
      this.searchFieldGroupParams = searchFieldGroupParams;
      this.$refs.myTableLayoutTab.onChangeWindowSize()
    },
    onChangeFieldGroupUserSearchParams({name, value}) {
      let searchFieldGroupUserParams = {...this.searchFieldGroupUserParams};
      searchFieldGroupUserParams[name] = value;
      this.searchFieldGroupUserParams = searchFieldGroupUserParams;
      this.$refs.myTableLayoutTab.onChangeWindowSize()
    },
    onChangeSortFieldGroupParams({name, value}, invalid) {
      let searchFieldGroupParams = {...this.searchFieldGroupParams};
      if (name == 'asc') {
        searchFieldGroupParams.orderBy = value ? 'ASC' : 'DESC';
      } else if (name == 'pageCount') {
        searchFieldGroupParams.pageSize = value;
      } else {
        searchFieldGroupParams.sortValue = value;
      }
      name == "pageCount" ? (searchFieldGroupParams["currentPage"] = 1) : "";
      this.searchFieldGroupParams = searchFieldGroupParams;
      if (!invalid){
        this.getItems();
      }
    },
    onChangeSortFieldGroupUserParams({name, value}, invalid) {
      let searchFieldGroupUserParams = {...this.searchFieldGroupUserParams};
      if (name == 'asc') {
        searchFieldGroupUserParams.orderBy = value ? 'ASC' : 'DESC';
      } else if (name == 'pageCount') {
        searchFieldGroupUserParams.pageSize = value;
      } else {
        searchFieldGroupUserParams.sortValue = value;
      }
      name == "pageCount" ? (searchFieldGroupUserParams["currentPage"] = 1) : "";
      this.searchFieldGroupUserParams = searchFieldGroupUserParams;
      if (!invalid){
        this.getFieldGroupUsers();
      }
    },
    /**
     * (共通)
     * 検索
     */
    onSearch(invalid) {
      this.searchFieldGroupParams["currentPage"] = 1;
      this.searchFieldGroupUserParams["currentPage"] = 1;
      if (!invalid) {
        this.getItems();
      }
    },
    itemRowBackground: function (item) {
      if (this.selectedTab === this.TAB_LIST_FIELD_GROUP) {
        return !item.is_editable ? 'background-gray-field-user' : '';
      }
      return '';
    },
    async getUserLabel() {
      if (this.roleLogin === COMPANY_SCENE) {
        this.USER_LABELS = this.ALL_USER_TABLE_LABELS
      } else {
        this.USER_LABELS = this.FIELD_GROUP_TABLE_LABELS_PARTNER
      }
      return this.USER_LABELS;
    },
    //async getListPartnerLeaderInField(isShowLoading = true) {
    //  //await Store.dispatch(`${USER_STORE}/getListPartnerLeaderInField`, {params: this.apiGetLeadersParams}, isShowLoading);
    //},

    async submitUploadInfo() {
      const formData = new FormData();
      formData.append('file', this.fileUpload);
      const rs = await Store.dispatch(`${USER_STORE}/bulkAddFieldGroupInfo`, formData);
      if (!rs.hasError && rs.data.status_code == 200) {
        this.getItems();
        await Store.dispatch("Toast/show", {
          status: 200,
          message: '登録しました。',
        });
        this.popups.isShowUploadForm = false;
      } else {
        if (rs?.response?.status == 400) {
          this.textDialogError = rs.response?.data?.message;
          this.popups.isShowErorDialog = true;
        }
      }
      this.closeConfirmUploadDialog();
    },

    async setStateForcedMove (item, status) {
      if (this.roleLogin !== COMPANY_SCENE) {
        await Store.dispatch("Error/show", {
          status: 200,
          message: 'ログインユーザーは元請に所属していません。',
        });
        return
      }

      const params = {
        field_user_id: item.id,
        flg_forced_move: parseInt(status),
      }

      const result = await Store.dispatch(`${USER_STORE}/setStatusApprove`, params);
      if (!result.hasError) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
        this.getFieldGroupUsers();
      }
    },

    checkFieldUser (item) {
      return item.role == 4 || item.role == 1
    },

    async setStateReceiptAllocation(item, status) {
      if (this.roleLogin !== COMPANY_SCENE) {
        await Store.dispatch("Error/show", {
          status: 200,
          message: 'ログインユーザーは元請に所属していません。',
        });
        return
      }

      const params = {
        field_user_id: item.id,
        flg_receipt_allocation: parseInt(status),
      }

      const result = await Store.dispatch(`${USER_STORE}/setStatusReceiptAllocation`, params);
      if (!result.hasError) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
        this.getFieldGroupUsers();
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.tab-select-header {
  height: 56px !important;
}

.sortLabel {
  font-size: 12px;
  height: 32px;
  margin-right: 16px;
}

.sortElement {
  max-width: 128px;
  margin-right: 24px;
}

.total_item {
  padding-bottom: 15px;
}

.cst-row {
  border-bottom: 1px solid;
  padding-top: 12px;
  border-color: #e0e0e0;
}

.cst-col {
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0 0 0 16px;
  width: calc(100% + 32px);
  text-align: center !important;
}

::v-deep   {
  .v-simple-checkbox--disabled {
    opacity: 0;
  }
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:first-child {
  padding: 0 0 0 16px !important;
  width: 60px !important;
  text-align: left !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}
@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 
}
</style>
<style>
tr.background-gray-field-user {
  background-color: #eee;
}

.tab-select-filed-user-screen {
  flex: 0 auto !important;
}
.field-user-custom-class-css .v-data-table thead.v-data-table-header tr th{
  min-width: 130px;
}
.alert-circle{
  color: #FF0000 !important;
}
</style>
